import styles from './Home.module.scss'

// import required modules
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Navigation, Pagination} from 'swiper'
import 'swiper/swiper-bundle.css'
import Service from "./secltions/services/Services";
import Team from "./secltions/team/team";
import {Link} from "react-router-dom";
import About from "./secltions/about/About";

SwiperCore.use([Navigation, Pagination]);


const slides = [
    // 'https://mycitydentist.am/wp-content/uploads/2021/08/Suren-1.png',
    {image: "/images/background_01.png", title: "Зубная Имплантация", text: "Почему стоит выбрать Станция Улыбок для вставления зубных имплантов.", link: "/slide1"},
    {image: "/images/background_02.png", title: "Голливудская улыбка (Виниры)", text: "Зубные виниры изготавливаются из материалов цвета зубов или светлее, которые изготавливаются для покрытия передней части зубов, чтобы улучшить внешний вид пациента.", link: "/slide2"},
    {image: "/images/background_03.png", title: "Лечение Заболеваний Десен", text: "В нашей клинике это совершается оптимальными методами:", link: "slide3"},
]

function Home() {


    return (
        <div className={styles.root}>
            <div>
                <div>
                    <div>
                        <Swiper
                            pagination={true}
                            vertical="true"
                            slidesPerView={1}
                        >
                            {slides.map((slide) => {
                                return (
                                    <SwiperSlide key={slide.image}>
                                        <div className={styles.slide}>
                                            <img className={styles.img} src={slide.image}/>
                                            <div className={styles.content}>
                                                <p className={styles.title}>{slide.title}</p>
                                                <p className={styles.text}>{slide.text}</p>
                                                <Link to={slide.link} className={styles.more}>Подробнее</Link>
                                            </div>
                                        </div>


                                    </SwiperSlide>
                                )
                            })}

                        </Swiper>
                    </div>

                    <About/>
                    <Team/>
                    <Service/>

                </div>
            </div>
        </div>
    );
}

export default Home;
