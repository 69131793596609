import styles from './Portfolio.module.scss'

function Portfolio() {
    return (
        <div className={styles.root}>
            <div>
                <div>
                    <div>
                        <img
                            src="http://placehold.it/900x400"
                            alt=""
                        />
                    </div>
                    <div>
                        <h1>Portfolio</h1>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a galley of
                            type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Portfolio;
