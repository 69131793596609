import styles from './Footer.module.scss'
import {NavLink, useLocation} from "react-router-dom";
import {useEffect} from "react";

const menuItems = [
    // {
    //     name: "Стоматология",
    //     link: "/dentistry",
    //     subsections: ""
    // },
    // {
    //     name: "Косметология",
    //     link: "/cosmetology",
    //     subsections: ""
    // },
    {
        name: "Цены Услуг",
        link: "/pricelist",
        subsections: ""
    },
    // {
    //     name: "Нашы Работы",
    //     link: "/portfolio",
    //     subsections: ""
    // },
    {
        name: "Контакты",
        link: "/contact",
        subsections: ""
    },
]

function Footer() {
    const location = useLocation()

    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [ location.pathname])

    return (
        <footer className={styles.footer}>
            <nav>
                <div className={styles.goal}>
                    <p className={styles.title}>Станция Улыбок</p>
                    <p className={styles.text}> Нашей главной целью является предоставление нашим пациентам стоматологических и косметологических
                        услуг высокого качества согласно мировым стандартам по лучшей цене.</p>

                </div>
                <ul className={styles.menuItems}>
                    {menuItems.map((item) => (
                        <li key={item.name}
                            className={`${location.pathname === item.link ? styles.activeItem : styles.item}`}>
                            <NavLink to={item.link}>
                                {item.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
            <p>
                &copy; Smile Station {new Date().getFullYear()}. Все права защищены:
            </p>
        </footer>
    );
}

export default Footer;
