import TitleText from "../../../../components/titleText/titleText";
import styles from './About.module.scss'


const About = () => {
    return (
        <div className={styles.root}>
            <TitleText title="О НАС" bigTitle="О НАС"
                       text=""/>
            <div className={styles.dental}>
                <div className={styles.image}>
                    <img
                        src="/images/team2.JPG"
                        alt=""
                    />
                </div>

                <div className={styles.rightList}>
                    <p className={styles.text}> Ура! Мы открылись! Дружная и сплоченная годами совместной работы команда собралась на нашей станции, чтобы подарить вам прекрасную улыбку и такой же прекрасный опыт общения со стоматологами.
                        <br />
                        <br />
                        Мы часто сталкиваемся с рекламой уровней качества, сервиса, высокого профессионализма специалистов, но редко задумываемся о том, что у истоков обеспечения всего этого стоит человеческий фактор, вернее, сам человек! Обычный, простой, со своими эмоциями, чувствами, симпатиями, желаниями и возможностями.
                        <br />
                        <br />
                        Поэтому, в нашем случае, хочется обратить ваше внимание на команду людей- сотрудников нашей клиники! Это они обеспечивают ту теплую, почти домашнюю атмосферу общения с нашими пациентами! Это они хотят дружить с вами, и помогать вам! А, как следствие, вы получите и высокое качество от профессионалов своего дела, и индивидуальный подход к каждому обращению, и эстетику, и финансовое удобство!
                        Мы приглашаем вас к общению и дружбе, и обещаем, что вы не будете разочарованы! С радостью ждем вас!
                    </p>
                </div>
            </div>
        </div>
    )
}


export default About