import TitleText from "../../components/titleText/titleText";
import styles from './Pricelist.module.scss'

const list = [
    {
        title: "Консультация",
        items: [
            {
                name: "Консультация всех специалистов и составление плана лечения",
                price: "0"
            },
            {
                name: "Компьютерная диагностика (чтение, анализ рентген снимков)",
                price: "500"
            },
            {
                name: "Бесплатный приём",
                price: "0"
            },
        ],
    },
    {
        title: "АНЕСТЕЗИЯ",
        items: [
            {
                name: "Аппликационная анестезия (одна карпула)",
                price: "200"
            },
            {
                name: "Инфильтрационная анестезия (одна карпула)",
                price: "500"
            },
            {
                name: "Проводниковая анестезия (одна карпула)",
                price: "500"
            },
        ],
    },
    {
        title: "ЛЕЧЕНИЕ ПУЛЬПИТА (без пломбы)",
        items: [
            {
                name: "Лечение пульпита одноканального зуба (анестезия (1 карпула),  механическая и медикаментозная обработка каналов,пломбировка каналов методом латеральной конденсации гуттаперчивыми штифтами, временная пломба)",
                price: "5300"
            },
            {
                name: "Лечение пульпита двухканального зуба (анестезия (1 карпула),  механическая и медикаментозная обработка каналов, пломбировка каналов методом латеральной конденсации гуттаперчивыми штифтами, временная пломба)",
                price: "8300"
            },
            {
                name: "Лечение пульпита трехканального зуба (анестезия (1 карпула),  механическая и медикаментозная обработка каналов, пломбировка каналов методом латеральной конденсации гуттаперчивыми штифтами, временная пломба)",
                price: "15000"
            },
            {
                name: "Лечение пульпита четырехканального зуба (анестезия (1 карпула),  механическая и медикаментозная обработка каналов, пломбировка каналов методом латеральной конденсации гуттаперчивыми штифтами, временная пломба)",
                price: "18300"
            },
        ],
    },
    {
        title: "ЛЕЧЕНИЕ ПЕРИОДОНТИТА (без пломбы)",
        items: [
            {
                name: "Лечение периодонтита одноканального зуба (анестезия (1 карпула), снятие старой пломбы, механическая и медикаментозная обработка корневых каналов, пломбирование каналов кальцийсодержащим препаратом, временная пломба)",
                price: "5100"
            },
            {
                name: "Лечение периодонтита двухканального зуба (анестезия (1 карпула), снятие старой пломбы, механическая и медикаментозная обработка корневых каналов, пломбирование каналов кальцийсодержащим препаратом, временная пломба)",
                price: "8100"
            },
            {
                name: "Лечение периодонтита трехканального зуба (анестезия (1 карпула), снятие старой пломбы, механическая и медикаментозная обработка корневых каналов, пломбирование каналов кальцийсодержащим препаратом, временная пломба)",
                price: "10100"
            },
            {
                name: "Лечение периодонтита четырехканального зуба (анестезия (1 карпула) , снятие старой пломбы, механическая и медикаментозная обработка корневых каналов, пломбирование каналов кальцийсодержащим препаратом, временная пломба)",
                price: "13100"
            },
        ],
    },
    {
        title: "ЭНДОДОНТИЯ",
        items: [
            {
                name: "Механическая и медикаментозная обработка 1 канала машинными инструментами",
                price: "3000",
            },
            {
                name: "Механическая и медикаментозная обработка 2 канала машинными инструментами",
                price: "5000",
            },
            {
                name: "Механическая и медикаментозная обработка 3 канала машинными инструментами",
                price: "6500",
            },
            {
                name: "Механическая и медикаментозная обработка 4 канала машинными инструментами",
                price: "9000",
            },
            {
                name: "Пломбирование 1 канала  кальцийсодержащимм препаратом",
                price: "1000",
            },
            {
                name: "Пломбирование 2 канала  кальцийсодержащимм препаратом",
                price: "2000",
            },
            {
                name: "Пломбирование 3 канала  кальцийсодержащимм препаратом",
                price: "2500",
            },
            {
                name: "Пломбирование 4 канала  кальцийсодержащимм препаратом",
                price: "3000",
            },
            {
                name: "Повторная механическая и медикаментозная обработка 1 канала после временной обтурации",
                price: "300",
            },
            {
                name: "Пломбировка корневых каналов одноканального зуба гуттаперчивыми штифтами методом латеральной конденсации",
                price: "1500",
            },
            {
                name: "Пломбировка корневых каналов двухканального зуба гуттаперчивыми штифтами методом латеральной конденсации",
                price: "2500",
            },
            {
                name: "Пломбировка корневых каналов трехканального зуба гуттаперчивыми штифтами методом латеральной конденсации",
                price: "3000",
            },
            {
                name: "Пломбировка корневых каналов четырехканального зуба гуттаперчивыми штифтами методом латеральной конденсации",
                price: "3500",
            },
            {
                name: "Временная пломба светового отверждения",
                price: "500",
            },
            {
                name: "Снятие временной пломбы",
                price: "200",
            },
            {
                name: "Временная пломба (водный дентин)",
                price: "300",
            },
            {
                name: "Извлечение анкерного металлического штифта",
                price: "1800",
            },
            {
                name: "Извлечение инородного тела из корневого канала (1 канал)",
                price: "3000",
            },
            {
                name: "Извленчение стекловолокнного штифта",
                price: "3000",
            },
            {
                name: "Распломбирование и медикаментозная обработка ранее обтурированного одноканального зуба",
                price: "4000",
            },
            {
                name: "Распломбирование и медикаментозная обработка ранее обтурированного двухканального зуба",
                price: "7000",
            },
            {
                name: "Распломбирование и медикаментозная обработка ранее обтурированного трехканального зуба",
                price: "10000",
            },
            {
                name: "Распломбирование и медикаментозная обработка ранее обтурированного четырехканального зуба",
                price: "13000",
            },
            {
                name: "Наложение девителизирующей пасты",
                price: "300",
            },
            {
                name: "Установка анкерного штифта",
                price: "2500",
            },
        ],
    },
    {
        title: "ГИГИЕНА И ОТБЕЛИВАНИЕ",
        items: [
            {
                name: "Удаление зубного налета воздушно-порошковым методом Air-Flow",
                price: "3600"
            },
            {
                name: "Удаление наддесневых и  поддесневыхтвердых твердых зубных отложений ультразвуком в области 1 зуба",
                price: "200"
            },
            {
                name: "Комплексная  чистка (удаление зубного налета воздушно-порошковым методом Air-Flow, удаление наддесневых и  поддесневыхтвердых твердых зубных отложений ультразвуком, фторирование, полировка)",
                price: "6000"
            },
            {
                name: "Обработка фторсодержащей пастой ДЕТАТРИН ( 6 зубов)",
                price: "900"
            },
            {
                name: "Обработка 1 зуба фторсодержащим лаком",
                price: "120"
            },
            {
                name: "Фторирование зубов (в/ч+ н/ч)",
                price: "3000"
            },
            {
                name: "Отбеливание Boost",
                price: "20000"
            },
        ],
    },
    {
        title: "ЛЕЧЕНИЕ КАРИЕСА, РЕСТАВРАЦИЯ КОМПОЗИТНЫМИ МАТЕРИАЛАМИ",
        items: [
            {
                name: "Лечение кариеса 1 поверхность (анестезия (1 карпула) ,препарирование, медикаментозная обработка,лечебная прокладка, изолирующая прокладка, ретракция десны, пломбирование композитным материалом светового отверждения, полирование)",
                price: "4500"
            },
            {
                name: "Лечение кариеса 2 поверхности (анестезия  (1 карпула),препарирование, медикаментозная обработка,лечебная прокладка, изолирующая прокладка, ретракция десны,  пломбирование композитным материалом светового отверждения, полирование)",
                price: "5500"
            },
            {
                name: "Лечение кариеса 3 поверхности (анестезия  (1 карпула),препарирование, медикаментозная обработка,лечебная прокладка, изолирующая прокладка, ретракция десны,  пломбирование композитным материалом светового отверждения, полирование)",
                price: "6000"
            },
            {
                name: "Лечение клиновидного дефекта",
                price: "3500"
            },
            {
                name: "Постановка пломбы световой полимеризации 1 поверхность",
                price: "2500"
            },
            {
                name: "Постановка пломбы световой полимеризации 2 поверхности",
                price: "3500"
            },
            {
                name: "Постановка пломбы световой полимеризации 3 поверхности",
                price: "4000"
            },
            {
                name: "Удаление старой пломбы",
                price: "300"
            },
            {
                name: "Наложение лечебной прокладки",
                price: "500"
            },
            {
                name: "Наложение изолирующей прокладки",
                price: "500"
            },
            {
                name: "Ретракция десны",
                price: "200"
            },
            {
                name: "Полирование и шлифование пломбы",
                price: "300"
            },
            {
                name: "Косметическая реставрация  (терапевтический винир или виниринг) I класса",
                price: "8000"
            },
            {
                name: "Косметическая реставрация  (терапевтический винир или виниринг) II класса",
                price: "9000"
            },
            {
                name: "Косметическая реставрация  (терапевтический винир или виниринг) III класса",
                price: "10000"
            },
            {
                name: "Билд-Ап ( Build- up,реставрация коронковой части зуба под ортопедическую конструкцию)",
                price: "5000"
            },
            {
                name: "Установка коффердама",
                price: "300"
            },
        ],
    },
    {
        title: "ПАРОДОНТОЛОГИЯ",
        items: [
            {
                name: "Кюретаж десневого кармана",
                price: "0"
            },
            {
                name: "Шинирование одного зуба материалом GlasSpan",
                price: "0"
            },
            {
                name: "Медицинская обработка десневого кармана",
                price: "0"
            },
            {
                name: "Профилактическая инъекция (линкомицин, дексаметазон, витамин В6, алоэ",
                price: "0"
            },
            {
                name: "Компплексное лечение пародонтита с применением фотодинамической терапии Fotosan с использованием дечебного геля Атридокс",
                price: "0"
            },
            {
                name: "Применение фотодинамической терапии Fotosan (одна процедура)",
                price: "0"
            },
            {
                name: "Сепарация десневого контура, десневая коррекция, иссечение гипертрофированной десны в области одного зуба",
                price: "0"
            },
        ]
    },
    {
        title: "ОРТОПЕДИЯ",
        groups: [
      {
                subtitle: "СЪЕМНОЕ ПРОТЕЗИРОВАНИЕ",
                subitems: [
                    {
                        name: "Полный съемный протез (акрил)",
                        price: "25000"
                    },
                    {
                        name: "Полный съемный протез Акрифри",
                        price: "45000"
                    },
                    {
                        name: "Частично съемный протез (акрил)",
                        price: "25000"
                    },
                    {
                        name: "Частично съемный протез Акрифри",
                        price: "45000"
                    },
                    {
                        name: "Частично съемный протез Квадротти, нейлоновый протез.",
                        price: "55000"
                    },
                    {
                        name: "Гипоаллергенный съемный протез (белая пластмасса, без наличия кламмера)",
                        price: "50000"
                    },
                    {
                        name: "Бюгель простой с опорно- удерживающими кламмерами (2 кламера) литье",
                        price: "55000"
                    },
                    {
                        name: "Бюгель простой с опорно- удерживающими кламмерами (3-4 кламера) литье",
                        price: "60000"
                    },
                    {
                        name: "Бюгель с кламмерами Роуча (фронтальные зубы)",
                        price: "55000"
                    },
                    {
                        name: "Бюгель с замками МК Бредент односторонний",
                        price: "60000"
                    },
                    {
                        name: "Бюгель с замками Бредент двухсторонний",
                        price: "70000"
                    },
                    {
                        name: "ЧСПП 1-2 зуба",
                        price: "15000"
                    },
                    {
                        name: "ЧСПП 3-4 зуба",
                        price: "20000"
                    },
                    {
                        name: "Временный протез акрил",
                        price: "20000"
                    },
                    {
                        name: "Условно-съемный протез с фиксацией на 4 имплантата (балочная или шаровидная фиксация)",
                        price: "150000"
                    },
                    {
                        name: "Работа в артикуляторе при помощи лицевой дуги",
                        price: "25000"
                    },
                ]
            }, {
                subtitle: "РЕМОНТ И ЦЕМЕНТИРОВКА",
                subitems: [
                    {
                        name: "Ремонт съемного протеза",
                        price: "5000"
                    },
                    {
                        name: "Перебазировка съемного протеза",
                        price: "5000"
                    },
                    {
                        name: "Коррекция протеза, изготовленного в сторонних клиниках",
                        price: "2000"
                    },
                    {
                        name: "Фиксация коронки на временный материал",
                        price: "200"
                    },
                    {
                        name: "Фиксаци виниров, коронок на композитный цемент",
                        price: "1000"
                    },
                    {
                        name: "Фиксация коронки на временный материал",
                        price: "500"
                    },
                    {
                        name: "Фиксация коронок на стеклоиономерный цемент",
                        price: "500"
                    },
                    {
                        name: "Снятие коронки 1 ед.",
                        price: "1000"
                    },
                    {
                        name: "Пришлифовка в области одного сегмента",
                        price: "500"
                    },
                    {
                        name: "Снятие слепков, изготовление диагностических моделей",
                        price: "1000"
                    },
                    {
                        name: "Металлокерамическая коронка VITA (Германия )",
                        price: "12000"
                    },
                    {
                        name: "Металлокерамическая коронка SHOFU HALO (Япония)",
                        price: "10000"
                    },
                    {
                        name: "Металлокерамическая коронка IVOCLAR  (Германия)",
                        price: "14000"
                    },
                    {
                        name: "Металлокерамическая коронка на имплантат Astra, Nobel, Ankylos, Straumann с ииспользованием оригинальных абатментов или фрезерованных титановых абатментов",
                        price: "28000"
                    },
                    {
                        name: "Безметалловая коронка с индивидуальным безметалловым абатментом на имплантат Astra, Nobel, Ankylos, Straumann",
                        price: "40000"
                    },
                    {
                        name: "Металлокерамическая коронка на имплантат Osstem, Alpha-Bio, Miss, BioHorizons с использованием оригинальных абатментов или фрезерованных титановых абатментов",
                        price: "25000"
                    },
                    {
                        name: "Мультиюнит с установкой в области одного  имплантата Osstem",
                        price: "10000"
                    },
                    {
                        name: "Мультиюнит с установкой в области одного  имплантата Astra, Nobel, Ankylos, Straumann",
                        price: "15000"
                    },
                    {
                        name: "Цельнолитая коронка (кхс)",
                        price: "10000"
                    },
                    {
                        name: "Цельнолитая коронка (кхс) с напылением",
                        price: "11000"
                    },
                    {
                        name: "Керамическая коронка на каркасе из драгоценного сплава золото/платина (без учета стоимости драг.металла)",
                        price: "25000"
                    },
                    {
                        name: "Коронка на основе диоксида циркония (метод CAD-CAM)",
                        price: "30000"
                    },
                    {
                        name: "Безметалловая коронка EMAX (с нанесением)",
                        price: "30000"
                    },
                    {
                        name: "Винир керамический EMAX (с нанесением)",
                        price: "30000"
                    },
                    {
                        name: "Цельноциркониевая коронка без нанесения",
                        price: "25000"
                    },
                    {
                        name: "Цельнофрезерованная коронка на зуб (временная)",
                        price: "5000"
                    },
                    {
                        name: "Вкладка керамическая EMAX",
                        price: "15000"
                    },
                    {
                        name: "Временная пластмассовая коронка  (1 зуб)",
                        price: "2000"
                    },
                    {
                        name: "Временная коронка на имплантат",
                        price: "3000"
                    },
                    {
                        name: "Ультратонкий винир, изготовленный методом  нанесения на рефрактор полевошпатной керамики",
                        price: "40000"
                    },
                    {
                        name: "Waxup (восковое моделирование коронковой части зуба)",
                        price: "2000"
                    },
                    {
                        name: "Mock-up (нанесение на зуб пластмассовой модели будущей формы зуба)",
                        price: "2000"
                    },
                    {
                        name: "Изготовление временной пластмассовой коронки методом CAD-CAM в лаборатории",
                        price: "5000"
                    },
                    {
                        name: "Изготовление фрезерованного металлического каркаса методом CAD-CAM в области одного зуба",
                        price: "4000"
                    },
                    {
                        name: "Фрезерованнный каркас- дуга из диоксида циркония на имплантатах под установку EMAX коронок, изготовленный методом CAD-CAM",
                        price: "200000"
                    },
                    {
                        name: "Хирургический шаблон для установки имплантатов до 4-х единиц",
                        price: "8000"
                    },
                    {
                        name: "Хирургический шаблон для установки имплантатов от 4 до 6 единиц",
                        price: "10000"
                    },
                    {
                        name: "Хирургический шаблон для установки имплантатов от 6 единиц",
                        price: "12000"
                    },
                    {
                        name: "Условно-съемный протез с опорой на имплантатах с металлическим каркасом без мультиюнитов",
                        price: "150000"
                    },
                    {
                        name: "Съемный протез со стабилизацией на атачменах",
                        price: "120000"
                    },
                ]
            },
            {
                subtitle: "ВКЛАДКИ",
                subitems: [
                    {
                        name: "Штифтовая культевая конструкция (КХС)",
                        price: "5000"
                    }, {
                        name: "Штифтовая культевая конструкция разборная (КХС)",
                        price: "6000"
                    }, {
                        name: "Штифтовая культевая конструкция (золото-платиновый сплав) без стоимости материала",
                        price: "5000"
                    }, {
                        name: "Штифтовая культевая конструкция серебряный сплав (СПС)",
                        price: "9000"
                    }, {
                        name: "Штифтовая культевая конструкция на основе диоксида циркония",
                        price: "14000"
                    }, {
                        name: "Штифтовая культевая конструкция на основе титанового сплава",
                        price: "14000"
                    }, {
                        name: "Штифтовая культевая конструкция на основе титанового сплава разборная",
                        price: "15000"
                    },
                ]
            },
        ]
    },
    // {
    //     title: "ОРТОДОНТИЯ",
    //     groups:[
    //         {
    //             subtitle: "ЛЕЧЕНИЕ НА НЕСЪЕМНОЙ АППАРАТУРЕ",
    //             subitems: [
    //                 {
    //                     name: "Металлическая брекет-система",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Металлическая самолигирующаяся брекет-система",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Сапфировая брекет-система",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Керамическая брекет- система",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Керамическая самолигирующаяся брекет-система",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Фиксация брекет-системы (1 зубной ряд)",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Активация брекет- системы",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Снятие брекет-системы",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Ретенционная каппа, ретейнер (1 челюсть)",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Снятие ретейнера (1 челюсть)",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Снятие ретейнера 1 ед.",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Изменение положения зуба Set up (1 зуб)",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Эластопозиционер",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Металлический брекет",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Металлический самолигирующийся брекет",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Керамический брекет",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Керамический самолигирующийся брекет",
    //                     price: ""
    //                 },
    //                 {
    //                     name: "Сапфировый брекет",
    //                     price: ""
    //                 },
    //             ]
    //         },{
    //             subtitle: "ЛЕЧЕНИЕ НА СЪЕМНОЙ АППАРАТУРЕ",
    //             subitems: [
    //                 {
    //                     name: "",
    //                     price: ""
    //                 }
    //             ]
    //         },{
    //             subtitle: "ДОПОЛНИТЕЛЬНЫЕ НЕСЪЕМНЫЕ АППАРАТЫ",
    //             subitems: [
    //                 {
    //                     name: "",
    //                     price: ""
    //                 }
    //             ]
    //         },
    //     ]
    // }
    {
        title: "ХИРУРГИЯ",
        items: [
            {
                name: "Установка классического имплантата Astra Tech (Швеция) Анестезия, операция, осмотр, наблюдение, наложение и снятие швов",
                price: "55000"
            },
            {
                name: "Установка классического имплантата Osstem (Корея) Анестезия, операция, осмотр, наблюдение, наложение и снятие швов",
                price: "30000"
            },
            {
                name: "Установка классического имплантата Ankylos (Германия) Анестезия, операция, осмотр, наблюдение, наложение и снятие швов",
                price: "55000"
            },
            {
                name: "Установка классического имплантата Nobel (США) Анестезия, операция, осмотр, наблюдение, наложение и снятие швов",
                price: "60000"
            },
            {
                name: "Открытый односторонний синус-лифтинг (без учета стоимости материала)",
                price: "30000"
            },
            {
                name: "Закрытый односторонний синус-лифтинг (без учета стоимости материала)",
                price: "15000"
            },
            {
                name: "Направленная костная регенерация (НКР) одна зона (без учета стоимости материала)",
                price: "20000"
            },
            {
                name: "Установка формирователя десны",
                price: "5000"
            },
            {
                name: "Процедура PRP (2 пробирки)",
                price: "8000"
            },
            {
                name: "Забор и пересадка костного блока (1 зона без учета стоимости материала)",
                price: "50000"
            },
            {
                name: "Забор аутотрансплантатной стружки",
                price: "20000"
            },
            {
                name: "Подсадка костного материала в лунку удаленного зуба (без стоимости материала)",
                price: "10000"
            },
            {
                name: "Удаление имплантата",
                price: "10000"
            },
            {
                name: "Расщепление альвеолярного гребня в области 1-3 зубов",
                price: "5000"
            },
            {
                name: "Резеция верхушки корня, гемисекция, цистэктомия",
                price: "8000"
            },
            {
                name: "Удаление зуба простое",
                price: "3000"
            },
            {
                name: "Удаление зуба сложное",
                price: "5000"
            },
            {
                name: "Удаление подвижного зуба",
                price: "2000"
            },
            {
                name: "Удаление зуба мудрости",
                price: "6000"
            },
            {
                name: "Удаление дистопированного зуба",
                price: "12000"
            },
            {
                name: "Удаление ретенированного зуба",
                price: "15000"
            },
            {
                name: "Удаление фрагмента (стенки) зуба",
                price: "2000"
            },
            {
                name: "Наложение лечебной повязки",
                price: "500"
            },
            {
                name: "Иссеченние капюшона",
                price: "2000"
            },
            {
                name: "Вскрытие периостального абсцесса, дренаж",
                price: "3000"
            },
            {
                name: "Коагуляция, перевязка, смена дренажа",
                price: "2000"
            },
            {
                name: "Наложение шва",
                price: "500"
            },
            {
                name: "Лечение альвеолита",
                price: "2000"
            },
            {
                name: "Кюретаж после удаления",
                price: "3000"
            },
            {
                name: "Пластика уздечки губы",
                price: "10000"
            },
            {
                name: "Пластика уздечки языка",
                price: "10000"
            },
            {
                name: "Открытый кюретаж в области 1-3 зубов",
                price: "3000"
            },
            {
                name: "Открытый кюретаж (1/2 челюсти)",
                price: "4000"
            },
            {
                name: "Открытый кюретаж (1 челюсть)",
                price: "8000"
            },
            {
                name: "Забор и пересадка мягких тканей с верхнего неба в область вмешательства",
                price: "25000"
            },
            {
                name: "Хирургическое удлинение коронковой части зуба",
                price: "3000"
            },
            {
                name: "Установка межкорневого минивинта",
                price: "5000"
            },
            {
                name: "Установка минивинта в область IZC, BUCCAL SHELF",
                price: "8000"
            },
        ],
    },
]

function Pricelist() {

    return (
        <div className={styles.root}>
            <TitleText title="Цены Услуг" bigTitle="Цены" text=""/>

            <div className={styles.blocks}>
                {list.map((block) => (
                    <div className={`${ block.groups ? styles.longBlock : styles.block}`}>
                        <p className={styles.title}>{block.title}</p>
                        <div className={`${ block.groups ? styles.groupItems : styles.items}`}>
                            {block.items && block.items.map((item) => (
                                <div className={styles.item}>
                                    <p className={styles.name}>{item.name}</p>
                                    <p className={styles.price}>{item.price} ₽</p>
                                </div>
                            ))}
                            {block.groups && block.groups.map((group)=>(
                                <div className={styles.group}>
                                    <p className={styles.subtitle}>{group.subtitle}</p>
                                    {group.subitems && group.subitems.map((item) => (
                                        <div className={styles.item}>
                                            <p className={styles.name}>{item.name}</p>
                                            <p className={styles.price}>{item.price} ₽</p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Pricelist;
