import TitleText from "../../components/titleText/titleText";
import {address, email, number} from "../../constants";

import { YMaps, Map, Placemark, ZoomControl } from "@pbe/react-yandex-maps";
import styles from './Contact.module.scss'

const center = [55.76, 37.64];

const coordinates = [55.874535, 37.522876];
const mapState = {
    center: coordinates,
    zoom: 17.21,
};

const mapSettings = {
    behaviors: ['default', 'scrollZoom'],
    type: 'yandex#map',
    controls: ['zoomControl'],
    darkMode: true, // Set dark mode
};


function Contact() {
    return (
        <div className={styles.root}>
            <TitleText title="Свяжитесь с нами" bigTitle="Контакты" text=""/>
            <div className={styles.content}>
                <h4 className={styles.title}>Хотите иметь улыбку без границ?</h4>
                <br/>
                <p className={styles.address}>Россия, Москва</p>
                <a className={styles.address}
                   href="https://www.google.com/maps/place/My+City+Dentist/@40.1921298,44.4984884,17z/data=!3m1!4b1!4m5!3m4!1s0x406abd1380a1df51:0x562acfb0eaa117a3!8m2!3d40.1921311!4d44.5006774"
                   target="_blank" rel="noopener">{address}</a>
                <a href={`mailto:${email}`} className={styles.email}>
                    {email}
                </a>
                <a href={`tel:${number.replace(/[{} ]/g, '')}`} className={styles.tel}>
                    {number}
                </a>

                <div className={styles.map}>
                    <YMaps query={{ load: "package.full" }}>
                        <Map state={mapState} width="100%" height="400px" options={mapSettings}>
                            <ZoomControl options={{ position: { top: 10, right: 10 } }} />
                            <Placemark geometry={coordinates} />
                        </Map>
                    </YMaps>
                </div>


            </div>
        </div>
    );
}

export default Contact;
