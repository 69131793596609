import {useState, useEffect} from 'react'
import {NavLink, useLocation} from "react-router-dom";
import Facebook from '../../icons/facebook-2.png'
import Instagram from '../../icons/instagram-2.png'
import {number, email} from "../../constants";
import {ReactComponent as SmileStationLogo} from "../../icons/Logo.svg";
import styles from "./Header.module.scss"

const menuItems = [
    // {
    //     name: "Стоматология",
    //     link: "/dentistry",
    //     clickable: false,
    //     subsections: [
    //         "Зубная имплантация",
    //         "Голливудская улыбка (виниры)",
    //         "Трансплантология",
    //         "Лечение заболеваний десен",
    //         "Удаление зубного налета",
    //         "Ортодонтия (брекеты)"
    //     ]
    // },
    // {
    //     name: "Косметология",
    //     link: "/cosmetology",
    //     clickable: false,
    //     subsections: [
    //         "Инъекции ботокса",
    //         "Увеличение и моделирование щек",
    //         "Филлеры (гиалуроновая кислота)",
    //         "Мезотерапия",
    //         "Подтяжка шеи, рук, пальцев и морщин",
    //     ]
    // },
    {
        name: "Главная",
        clickable: true,
        link: "/",
        subsections: "",
    },
    {
        name: "Цены Услуг",
        clickable: true,
        link: "/pricelist",
        subsections: "",
    },
    // {
    //     name: "Нашы Работы",
    //     clickable: true,
    //     link: "/portfolio",
    //     subsections: ""
    // },
    {
        name: "Контакты",
        clickable: true,
        link: "/contact",
        subsections: ""
    },
]


function Header() {
    const location = useLocation()

    const [scrolled, setScrolled] = useState(true);
    //
    // const handleScroll = () => {
    //     if (window.scrollY > 0) {
    //         setScrolled(true);
    //     } else {
    //         setScrolled(false);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <nav className={`${styles.root} ${scrolled ? styles.scrolled : ''}`}>
            <div className={styles.firstPart}>
                <div className={styles.socials}>
                    <a href="/"> <img src={Instagram}/></a>
                    <a href="/"> <img src={Facebook}/></a>
                </div>
                <a href={`tel:${number.replace(/[{} ]/g, '')}`} className={styles.tel}>
                    {number}
                </a>
                <a href={`mailto:${email}`} className={styles.email}>
                    {email}
                </a>
            </div>
            <div className={styles.content}>
                <NavLink to="/" className={styles.logo}>
                    <SmileStationLogo/>
                </NavLink>
                <ul className={styles.menuItems}>
                    {menuItems.map((item) => (
                        <li key={item.name}
                            className={`${location.pathname === item.link ? styles.activeItem : styles.item}`}>
                            <NavLink to={item.clickable ? item.link : location.pathname}>
                                {item.name}
                            </NavLink>
                            {!!item.subsections && <ul className={styles.subsections}>
                                {item.subsections.map((subsection, index) => {
                                    return (
                                        <li className={styles.subsection}><NavLink to={`${item.link}/${index}`}>
                                            {subsection}
                                        </NavLink></li>
                                    )
                                })}
                            </ul>}
                        </li>
                    ))}
                </ul>
            </div>

        </nav>
    );
}

export default Header;
