import TitleText from "../../../../components/titleText/titleText";
import styles from './Services.module.scss'
import {useState} from "react";

const servicesList = [
    {
        title: "Гигиена и отбеливание",
        items: [
            {
                name: "Удаление зубного налета воздушно-порошковым методом Air-Flow",
                price: "3600"
            },
            {
                name: "Удаление наддесневых и  поддесневыхтвердых твердых зубных отложений ультразвуком в области 1 зуба",
                price: "200"
            },
            {
                name: "Комплексная  чистка (удаление зубного налета воздушно-порошковым методом Air-Flow, удаление наддесневых и  поддесневыхтвердых твердых зубных отложений ультразвуком, фторирование, полировка)",
                price: "6000"
            },
            {
                name: "Обработка фторсодержащей пастой ДЕТАТРИН ( 6 зубов)",
                price: "900"
            },
            {
                name: "Обработка 1 зуба фторсодержащим лаком",
                price: "120"
            },
            {
                name: "Фторирование зубов (в/ч+ н/ч)",
                price: "3000"
            },
            {
                name: "Отбеливание Boost",
                price: "20000"
            },
        ],
    },
    {
        title: "Терапия",
        items: []
    }, {
        title: "Хирургия",
        items: [{
            name: "Удаление зуба простое",
            price: "3000"
        },
            {
                name: "Удаление зуба сложное",
                price: "5000"
            },
            {
                name: "Удаление подвижного зуба",
                price: "2000"
            },
            {
                name: "Удаление зуба мудрости",
                price: "6000"
            }]
    }, {
        title: "Имплантация",
        items: []
    }, {
        title: "Ортопедия (протезирование)",
        items: []
    }, {
        title: "Ортодонтия (выравнивание зубов, исправление прикуса) ",
        items: []
    }, {
        title: "Пародонтология",
        items: []
    },
]
const Service = () => {
    const [isOpen, setOpen] = useState("")
    return (
        <div className={styles.root}>
            <TitleText title="Основные Услуги" bigTitle="Услуги"
                       text="Стоматологическая и косметологическая клиника Станция Улыбок был основан в 2023г. в России, в Москве. Нашей главной целью является предоставление нашим пациентам стоматологических и косметологических услуг высокого качества согласно мировым стандартам по лучшей цене."/>
            <div className={styles.dental}>
                <div className={styles.image}>
                    <img
                        src="/images/team.jpeg"
                        alt=""
                    />
                </div>
                <div className={styles.rightList}>
                    <h2>Стоматология</h2>
                    <p>Опытный персонал Станция Улыбок сделает все возможное,
                        чтобы предоставить вам высококачественные стоматологические услуги, от комплексного
                        стоматологического лечения до эстетической реставрации.
                    </p>
                    <ul className={styles.list}>
                        {servicesList.map((service, index) => (
                            <>
                                <li onClick={() => {
                                    setOpen(index)
                                }}>{service.title}</li>
                                {!!service.items.length && isOpen === index && <> <ul className={styles.insertedList}>
                                    {service.items.map((s) => (
                                        <li>{s.name}{" - "}{s.price} ₽</li>
                                    ))}
                                </ul> </>}
                            </>
                        ))}
                    </ul>
                </div>
            </div>
            {/*     <div className={styles.cosmetology}>
                <div className={styles.leftList}>
                    <h2>Косметология</h2>
                    <p>Для каждого человека очень важно иметь красивую, ухоженную внешность. Станция Улыбок
                        предоставляет высококачественные косметологические услуги с использованием
                        самых лучших материалов. Мы օказываем следующие косметологические
                        услуги։
                    </p>
                    <ul className={styles.list}>
                        <li>Инъекции ботокса</li>
                        <li>Увеличение и моделирование щек</li>
                        <li>Филлеры (гиалуроновая кислота)</li>
                        <li>Мезотерапия</li>
                        <li>Подтяжка шеи, рук, пальцев и морщин</li>
                    </ul>
                </div>
                <div className={styles.image}>
                    <img
                        src="/images/team.jpeg"
                        alt=""
                    />
                </div>
            </div>*/}
        </div>
    )
}


export default Service