import styles from './titleText.module.scss'

const TitleText = ({title, bigTitle, text}) => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <span className={styles.backTitle}>{bigTitle}</span>
                <h1>{title}</h1>
            </div>
            <p className={styles.text}>
                {text}
            </p></div>
    )
}
export default TitleText