import React from "react";
import "./App.css";
import {Route, Routes} from "react-router-dom";
import Header from "./pages/header/Header";
import Home from "./pages/home/Home";
import Footer from "./pages/footer/Footer";
import Portfolio from "./pages/portfolio/Portfolio";
import Contact from "./pages/contact/Contact";
import "./index.scss"
import Pricelist from "./pages/pricelist/Pricelist";
import ContentMore from "./pages/contentMore/contentMore";

function App() {
    return (
        <>
            <Header/>
            <Routes className="page">
                <Route path="/" element={<Home/>}/>
                <Route path="/pricelist" element={<Pricelist/>}/>
                <Route path="/portfolio" element={<Portfolio/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/slide1" element={<ContentMore/>}/>
                <Route path="/slide2" element={<ContentMore/>}/>
                <Route path="/slide3" element={<ContentMore/>}/>
            </Routes>
            <Footer/>
        </>
    );
}

export default App;
