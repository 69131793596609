import styles from "./team.module.scss";
import TitleText from "../../../../components/titleText/titleText";
import Facebook from "../../../../icons/facebook-2.png";
import Instagram from "../../../../icons/instagram-2.png";

const conotent = [
    // 'https://mycitydentist.am/wp-content/uploads/2021/08/Suren-1.png',
    {
        image: "/staff/6.jpg",
        name: "Мнацаканян Сона Мишаевна", //Sona,
        speciality: "Генеральный директор"
     },
    {
        image: "/staff/7.jpg",
        name: "Манукян Ирина Левоновна", //irina,
        speciality: "Главный врач, имплантолог - ортопед"
     },
    {
        image: "/staff/1.jpg",
        name: "Амирханова Екатерина Георгиевна", //ekaterina,
        speciality: "терапевт - ортопед"
     },
    {
        image: "/staff/8.jpg",
        name: "Овсепян Артем Вазгенович", //artyom,
        speciality: "ортопед - хирург"
     },
    {
        image: "/staff/10.jpg",
        name: "Синельникова Анастасия Михайловна",  //anastasia,
        speciality: "хирург"
     },
    {
        image: "/staff/9.jpg",
        name: "Овсепян Лариса Вазгеновна ", //larisa,
        speciality: "терапевт - хирург"
     },
    {
        image: "/staff/4.jpg",
        name: "Солодухина Мария Александровна", //maria,
        speciality: "терапевт"
     },
    {
        image: "/staff/2.jpg",
        name: "Рустамов Арзуман Султанович", //arzuman,
        speciality: "терапевт - хирург"
     },
    {
        image: "/staff/5.jpg",
        name: "Мовсесян Эрмине Вачагановна", //hermine,
        speciality: "ст. медсестра"
     },
    // "http://placehold.it/100x100"
]


const Team = () => {
    return (
        <div className={styles.team}>
            <TitleText title="Наша Команда" bigTitle="Команда"
                       text="«Объединение- это начало. Держаться вместе- это прогресс. Совместная работа- это успех.» Генри Форд."/>
            <div className={styles.teamGallery}>
                {conotent.map((item) => {
                    return (
                        <div className={styles.box} key={item.image}>
                            <div className={styles.contentTeam}
                                 style={{backgroundImage: `url('${item.image}')`}}/>
                            <div className={styles.contentTeamText}>
                                <h5>{item.name}</h5>
                                <p className={styles.speciality}>{item.speciality}</p>
                                {/*<div className={styles.teamIcons}>*/}
                                {/*    <a href="https://www.facebook.com/MyCityDentist/">*/}
                                {/*        <img src={Facebook} alt=""/></a>*/}
                                {/*    <a href="https://instagram.com/my_city_dentist?utm_medium=copy_link">*/}
                                {/*        <img src={Instagram}/></a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}
export default Team